import { graphql, PageProps } from 'gatsby';
import React, { useState } from 'react';

import { Grid, Stack } from '../../components/Core';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import InputButton from '../../components/InputButton';
import Layout from '../../components/Layout';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import TeamMember from '../../components/TeamMember';
import Text from '../../components/Text';
import { TEXT_STYLES } from '../../components/Text/constants';
import { BUTTON_STYLES } from '../../constants/ui';
import {
  createImageWithArtDirection,
  serializeImageForImageComponent,
} from '../../utils/serializeImageForImageComponent';
import KinequickForm from '../components/Accueil/KinequickForm';

const STATIC_STRINGS = {
  EN: {
    filters: 'Select a specialty',
  },
  FR: {
    filters: 'Choisissez une spécialité',
  },
};

type IStaffProps = PageProps<Queries.PageStaffQuery, IPageContext>;

const StaffTemplate: FCWithClassName<IStaffProps> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpCentre;
  const staff = pageData?.servicesEtStaff?.staff;
  const pageTemplateData =
    'contenuCentre' in data.wpPage.template
      ? data.wpPage.template.contenuCentre
      : null;
  const [filteredPosition, setFilteredPosition] = useState<string | undefined>(
    undefined
  );
  if (!pageData || !pageTemplateData) {
    return null;
  }
  const heroCta = {
    internalLink: pageTemplateData.sectionHeader.lienPageKinequick,
    style: BUTTON_STYLES.SOLID,
    titre: pageTemplateData?.sectionHeader.label,
  };
  const availablePositions = Array.from(
    new Set(
      staff.flatMap((staffMember) =>
        staffMember?.staffInformations.poste?.map((poste) => poste?.title)
      )
    )
  );

  const servicesAvailable = Object.fromEntries(
    staff.flatMap((staffMember) => {
      return (
        staffMember?.staffInformations?.poste
          ?.map((poste) => {
            return poste?.title && poste?.slug
              ? [poste.title, poste.slug]
              : null;
          })
          .filter((x) => x) || []
      );
    })
  );

  const heroTitle = data.wpPage.hero?.titre?.replace(
    '{{CENTRE}}',
    pageData.title
  );
  const STAFF_METAS: {
    [key in CountryCode]?: { [item in 'metaDesc' | 'title']: string };
  } = {
    EN: {
      metaDesc: `Physiotherapists, osteopaths, podiatrists, sports coaches ... Discover the expert practitioners of our medical center Uperform at ${pageData.title}`,
      title: `Uperform medical center's team at ${pageData.title}`,
    },
    FR: {
      metaDesc: `Kinésithérapeutes, ostéopathes, podologues, coachs sportifs... Découvrez les praticiens spécialisés de notre centre médical Uperform à ${pageData.title}`,
      title: `Équipe du centre médical Uperform à ${pageData.title}`,
    },
  };
  const seoDerivedFromCentres = {
    ...pageData.seo,
    ...STAFF_METAS[props.pageContext.currentLanguage.code],
    opengraphDescription:
      STAFF_METAS[props.pageContext.currentLanguage.code]?.metaDesc,
    opengraphTitle: STAFF_METAS[props.pageContext.currentLanguage.code]?.title,
  };
  const filterStaffMembers = (position: string) => {
    setFilteredPosition(position);
  };
  const filteredStaff = filteredPosition
    ? staff.filter((staffMember) => {
        return staffMember?.staffInformations.poste
          ?.map((poste) => poste?.title)
          ?.includes(filteredPosition);
      })
    : staff;
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: pageData.featuredImage?.node,
    mobileImage: pageData.coordonnees.imageMobile,
  });

  const frontpageData =
    'contenuHomepage' in data.frontpage.template
      ? data.frontpage.template.contenuHomepage
      : null;

  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={{ ...pageData, seo: seoDerivedFromCentres }} />
      <Stack className="space-y-gap">
        <Hero
          backgroundImage={heroImageWithArtDirection}
          centreInfos={{ ...pageData.coordonnees, name: pageData.title ?? '' }}
          couleurTitre={null}
          ctas={[heroCta]}
          type={HERO_TYPE.CENTRE}
          heading={heroTitle}
          kinequickForm={
            frontpageData?.ctaCarroussel && (
              <KinequickForm
                cta={frontpageData?.ctaCarroussel[0] || undefined}
                servicePlaceholder="Services"
                centrePlaceholder="Centres"
                data={
                  data.configurations?.nodes?.[0]?.configuration.linksForRdv
                }
                defaultCentre={{
                  slug: pageData.slug,
                }}
                lang={pageContext.currentLanguage.code}
                hideCentre
              />
            )
          }
        />

        <SectionWithTitle>
          <Text variant={TEXT_STYLES.H4} className="mx-auto mb-4 text-center">
            {
              STATIC_STRINGS[
                props.pageContext.currentLanguage?.code as 'FR' | 'EN'
              ]?.filters
            }
          </Text>
          <Grid className="mb-8 justify-center w-full gap-4 grid-cols-[repeat(auto-fit,250px)]">
            {availablePositions?.map(
              (position) =>
                position && (
                  <InputButton
                    key={position}
                    isActive={position === filteredPosition}
                    handleClick={() => filterStaffMembers(position)}
                  >
                    {position}
                  </InputButton>
                )
            )}
          </Grid>
          <Grid className="mb-8 justify-center w-full gap-x-4 xl:gap-x-12 gap-y-12 grid-cols-1 sm:grid-cols-[repeat(auto-fit,minmax(250px,1fr))]">
            {filteredStaff?.map((staffMember) => {
              if (!staffMember) return null;
              const image = staffMember.staffInformations.photo;
              return (
                <TeamMember
                  key={staffMember.id}
                  image={serializeImageForImageComponent({ image })}
                  firstName={staffMember.staffInformations.prenom}
                  gsm={staffMember.staffInformations.gsm}
                  lastName={staffMember.staffInformations.nom}
                  mail={staffMember.staffInformations.email}
                  description={staffMember.staffInformations.description}
                  className="max-w-lg"
                  languages={staffMember.staffInformations.languages}
                  textLink={staffMember.staffInformations.textLink}
                  linkType={staffMember.staffInformations.linkType}
                  externalLink={staffMember.staffInformations.externalLink}
                  phoneLink={staffMember.staffInformations.phoneLink}
                />
              );
            })}
          </Grid>
        </SectionWithTitle>
      </Stack>
    </Layout>
  );
};

export default StaffTemplate;

export const query = graphql`
  query PageStaff($id: String, $currentLanguageSlug: String) {
    wpCentre(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      coordonnees {
        adresse
        email
        facebook
        gps
        instagram
        lienRdv
        telephone
        description
        imageMobile {
          ...MediaItemFragment
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            publicURL
          }
        }
        nomLong
      }
      servicesEtStaff {
        staff {
          ...TeamMemberFragment
        }
      }
    }
    wpPage(
      language: { slug: { eq: $currentLanguageSlug } }
      template: { templateName: { eq: "Page Centre" } }
    ) {
      hero {
        couleurTitre
        imageMobile {
          ...MediaItemFragment
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 840
                breakpoints: [840]

                layout: CONSTRAINED
              )
            }
            publicURL
          }
        }
        tagline
        titre
      }
      template {
        ... on WpTemplate_PageCentre {
          contenuCentre {
            sectionHeader {
              label
              phraseDaccueil
              lienPageKinequick {
                ... on WpPage {
                  id
                  uri
                }
              }
            }
            sectionStaff {
              label
              titre
            }
          }
        }
      }
    }
    frontpage: wpPage(
      isFrontPage: { eq: true }
      language: { slug: { eq: $currentLanguageSlug } }
    ) {
      template {
        ... on WpTemplate_PageDaccueil {
          contenuHomepage {
            ctaCarroussel {
              ... on WpTemplate_PageDaccueil_Contenuhomepage_CtaCarroussel_Cta {
                anchor
                externalLink
                internalLink {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                style
                titre
              }
            }
          }
        }
      }
    }
    configurations: allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          linksForRdv {
            target
            url
            service {
              ... on WpService {
                title
                slug
              }
            }
            place {
              ... on WpCentre {
                title
                slug
              }
            }
          }
        }
      }
    }
  }
`;
