import React, { MouseEvent } from 'react';

import { tw } from '../../utils/tailwind';
import ThemedButton from '../ThemedButton';

interface IInputButtonProps {
  handleClick: (e: MouseEvent<HTMLButtonElement>) => void;
  isActive?: boolean;
  isDisabled?: boolean;
}

const InputButton: FCWithClassName<IInputButtonProps> = (props) => {
  const { isActive = false, isDisabled = false } = props;
  return (
    <ThemedButton
      disabled={isDisabled}
      onClick={props.handleClick}
      className={tw(isDisabled ? 'cursor-default' : 'cursor-pointer', isActive ? 'bg-primaryMain' : 'bg-accent-100')}
      size="sm"
    >
      {props.children}
    </ThemedButton>
  );
};

export default InputButton;
